
/* ==========================================================================
	Navbar
	========================================================================== */

	.navbar-centered {
		.navbar {
			@media (min-width: $grid-float-breakpoint) {
				display: flex;
				align-items: center;
			}
		}
		}
		
		.navbar {
			// @extend .font-bold;
			font-weight: 600;
			width: 100%;
			font-size: .9rem;
			// letter-spacing: .1rem;
		}
		
		.navbar-nav {
			// @extend .navbar-right;
		
			>li {
		
				>a {
					position: relative;
		
					&::after {
						content: '';
						position: absolute;
						bottom: .5rem;
						left: 1rem;
						right: auto;
						height: 1px;
						width: 0;
						opacity: 0;
						background-color: $brand-primary;
						transition: width ease .2s, opacity .5s;
					}
				}
		
				&:hover>a::after,
				&:active>a::after,
				&:focus>a::after,
				&.active>a::after {
					width: 1.5rem;
					opacity: 1;
				}
			}
		}
		
		.index-nav {
			@extend .pull-right;
			display: inline-block;
			line-height: $navbar-height;
			padding-right: 10px;
			color: $navbar-default-toggle-icon-bar-bg;
			text-transform: uppercase;
			// font-weight: 700;
		
			@media (min-width: $grid-float-breakpoint) {
				display: none;
			}
		}
		
		.navbar-brand {
			display: none;
		
			img {
				display: block;
				width: 6rem;
			}
		}
		