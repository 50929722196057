//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    60px !default;
$navbar-margin-bottom:             0 !default;
$navbar-border-radius:             $border-radius-base !default;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             white !default;
$navbar-default-bg:                $brand-second !default;
$navbar-default-border:            transparent !default;

// Navbar links
$navbar-default-link-color:                white !default;
$navbar-default-link-hover-color:          lighten($brand-second, 25%) !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         lighten($brand-second, 25%) !default;
$navbar-default-link-active-bg:            transparent !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         $navbar-default-link-hover-color !default;
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           $navbar-default-bg !default;
$navbar-default-toggle-icon-bar-bg:        $brand-primary !default;
$navbar-default-toggle-border-color:       $brand-primary !default;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      #fff !default;
$navbar-inverse-bg:                         $brand-success !default;
$navbar-inverse-border:                     transparent !default;

// Inverted navbar links
$navbar-inverse-link-color:                 #fff !default;
$navbar-inverse-link-hover-color:           $brand-primary !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          #fff !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 5%) !default;
$navbar-inverse-link-disabled-color:        #ccc !default;
$navbar-inverse-link-disabled-bg:           transparent !default;


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px !default;
$nav-link-hover-bg:                         $gray-lighter !default;

$nav-disabled-link-color:                   $gray-light !default;
$nav-disabled-link-hover-color:             $gray-light !default;

//== Tabs
$nav-tabs-border-color:                     $gray-lighter !default;

$nav-tabs-link-hover-border-color:          $gray-lighter !default;

$nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-color:          $brand-primary !default;
$nav-tabs-active-link-hover-border-color:   $gray-lighter !default;

$nav-tabs-justified-link-border-color:            $gray-lighter !default;
$nav-tabs-justified-active-link-border-color:     $body-bg !default;

//== Pills
$nav-pills-border-radius:                   $border-radius-base !default;
$nav-pills-active-link-hover-bg:            $component-active-bg !default;
$nav-pills-active-link-hover-color:         $component-active-color !default;


// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-toggle-icon-bar-bg:         #fff !default;
$navbar-inverse-toggle-border-color:        darken($navbar-inverse-bg, 10%) !default;
